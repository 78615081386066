import React from 'react'
import Content, { HTMLContent } from '../components/Content'
import bannerImage from '../img/contact-bg.jpg'
import Banner from '../components/Banner'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'

export const PageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <Layout>
      <Banner title={title} />
      <section className="section md-page">
        <div className="container">
          <PageContent content={content} />
        </div>
      </section>
    </Layout>
  )
}

export default ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <PageTemplate
      contentComponent={HTMLContent}
      title={post.frontmatter.title}
      content={post.html}
    />
  )
}

export const contactPageQuery = graphql`
  query PageContent($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
